import Vue from "vue";
export let settings;

export async function loadAppSettings() {
  settings = { serverUrl: null };
  try {
    const d = new Date();
    if (process.env.NODE_ENV === "development") {
      try {
        let response = await Vue.axios.get("/appsettings.Development.json");
        if (response && response.status === 200)
          settings.serverUrl = response.data && response.data.serverUrl;
      } catch {
        console.warn("no appsettings.Development.json found");
      }
      if (!settings.serverUrl)
        settings.serverUrl = "http://" + window.location.hostname + ":5100";
    } else {
      try {
        let response = await Vue.axios.get("/appsettings.Production.json");
        settings = response.data;
      } catch {
        let response = await Vue.axios.get("/appsettings.json");
        settings = response.data;
      }
    }
    settings.timeStamp = d.getTime();
    sessionStorage.setItem("settings", JSON.stringify(settings));
  } catch (err) {
    console.error(err);
  }
}
export const optionAxios = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
