<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { settings, loadAppSettings } from "@/eyecon.js";
export default {
  async created() {
    document.title = "TOPVISTA / EYECON DIGITAL SIGNAGE";
    if (!settings || !settings.serverUrl) {
      await loadAppSettings();
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background-color: #f1f2f3;
}
</style>
