import { settings, optionAxios } from "@/eyecon.js";
import Vue from "vue";
const state = {
  cloudLogin: null,
};
const getters = { cloudLogin: (state) => state.cloudLogin };
const actions = {
  // eslint-disable-next-line no-unused-vars
  async __checkAccountExist({ commit }, id) {
    var ret = false;
    var payload = { id: id };
    try {
      var response = await Vue.axios.post(
        settings.serverUrl + "/CloudLogin/check-account-exist",
        payload,
        optionAxios
      );
      if (response && response.status === 200) {
        ret = true;
      } else {
        ret = false;
      }
    } catch (err) {
      //console.error(err);
      ret = false;
    }
    return ret;
  },

  async __login({ commit }, payload) {
    var ret = false;
    try {
      var response = await Vue.axios.post(
        settings.serverUrl + "/CloudLogin/login",
        payload,
        optionAxios
      );
      if (response && response.status === 200) {
        commit("__loginSuccess", response.data);
        if (response.data) ret = true;
      } else {
        ret = false;
      }
    } catch (err) {
      //console.error(err);
      ret = false;
    }
    return ret;
  },
};
const mutations = {
  __loginSuccess: (state, ret) => {
    state.cloudLogin = ret;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
